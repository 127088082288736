import './App.css';
import Iframe from 'react-iframe';

function App() {
  return (
    <div className="App">
      <header className="App-header">

	<Iframe url="https://701ce74e9d83.ngrok.app/"
        width="100%"
        height="100%"
        id=""
        className=""
        display="block"
        position="fixed"
	/>
      </header>
    </div>
  );
}

export default App;
